<template>
  <b-row v-if="serviceHistoryProps.tripHistoryObject">
    <b-col
      md="6"
      class="border p-1"
    >
      <p
        class="py-2 text-center font-weight-bold font-18 text-white"
        style="background-color: #7367f0;"
      >
        {{ serviceHistoryProps.tripHistoryObject.booking_id }}
      </p>
      <p class="mb-50">
        <span class="font-weight-bold">{{ $t('Service Type') }}: </span>{{ getServiceType }}
      </p>
      <p class="mb-50">
        <span class="font-weight-bold">{{ $t('Service Location') }}: </span>{{ serviceHistoryProps.tripHistoryObject.s_address }}
      </p>
      <p class="mb-50">
        <span class="font-weight-bold">{{ $t('Scheduled Date') }}: </span>{{ serviceHistoryProps.tripHistoryObject.timeslot.booking_date }}
      </p>
      <div
        v-if="cartDetails.length > 0"
        class="wrap-card-details mt-2"
      >
        <b-media
          v-for="(item, index) of cartDetails"
          :key="index"
          class="mb-2 border-bottom"
        >
          <template #aside>
            <b-avatar :src="isMainServiceAvailable(item) ? $helpers.imageHelper(item.provider_service.main_service.picture) : null" />
          </template>
          <h6 v-if="isMainServiceAvailable(item)">
            {{ item.provider_service.main_service.service_name }}
          </h6>
          <p
            v-if="isMainServiceAvailable(item) && item.provider_service.main_service.service_category"
            class="mb-0"
          >
            {{ $t('Category') }}: {{ item.provider_service.main_service.service_category.service_category_name }}
          </p>
          <p class="mb-0">
            {{ $t('Service Qty') }}: {{ item.quantity }}
          </p>
          <p class="mb-0">
            {{ $t('Service Price') }}: {{ item.service_price }}
          </p>
          <p class="mb-0">
            {{ $t('Total') }}: {{ item.total_service_price }}
          </p>
        </b-media>
      </div>
      <b-card
        v-if="serviceHistoryProps.tripHistoryObject && serviceHistoryProps.tripHistoryObject.history && serviceHistoryProps.tripHistoryObject.history.length > 0"
        class="mt-1"
      >
        <h4 class="mb-1 pl-0 font-weight-bold">
          <b>{{ $t('History') }}</b>
        </h4>
        <app-timeline>
          <app-timeline-item
            v-for="(item, index) of serviceHistoryProps.tripHistoryObject.history"
            :key="index"
            :title="item.status + ' at ' + item.created_at"
            :variant="index == serviceHistoryProps.tripHistoryObject.history.length - 1 ? 'secondary' : 'primary'"
          />
        </app-timeline>
      </b-card>
      <div
        v-if="showReSechdule"
        class="mt-2 text-center"
      >
        <button
          class="btn btn-primary"
          @click="$emit('request-schedule')"
        >
          {{ $t('Reschedule') }}
        </button>
      </div>
    </b-col>
    <b-col
      v-if="serviceHistoryProps.tripHistoryObject.user"
      md="6"
      class="border p-1 border-left-0"
    >
      <div class="row mb-2">
        <div
          class="col-md-6"
          style="height: 150px;"
        >
          <p class="text-center mb-0">
            {{ $t('Before') }}
          </p>
          <img
            :src="
              serviceHistoryProps.tripHistoryObject.before_image ? $helpers.imageHelper(serviceHistoryProps.tripHistoryObject.before_image) : require('@/assets/images/app-images/choose_image.svg')
            "
            class="w-100 h-100"
            style="height: 120px !important;object-fit: contain;"
            @click="
              $emit('upload-image-service', {
                status: 'PICKEDUP',
                service: serviceHistoryProps.tripHistoryObject,
              })
            "
            @error="$helpers.imageError"
          >
        </div>
        <div
          class="col-md-6"
          style="height: 150px;"
        >
          <p class="text-center mb-0">
            {{ $t('After') }}
          </p>
          <img
            :src="serviceHistoryProps.tripHistoryObject.after_image ? $helpers.imageHelper(serviceHistoryProps.tripHistoryObject.after_image) : require('@/assets/images/app-images/choose_image.svg')"
            class="w-100 h-100"
            style="height: 120px !important;object-fit: contain;"
            @click="
              $emit('upload-image-service', {
                status: 'DROPPED',
                service: serviceHistoryProps.tripHistoryObject,
              })
            "
            @error="$helpers.imageError"
          >
        </div>
      </div>
      <b-media>
        <template #aside>
          <b-avatar :src="$helpers.imageHelper(serviceHistoryProps.tripHistoryObject.user.picture)" />
        </template>
        <h6>{{ serviceHistoryProps.tripHistoryObject.user.first_name }} {{ serviceHistoryProps.tripHistoryObject.user.last_name }}</h6>
        <div class="mb-0">
          <ul class="unstyled-list list-inline">
            <li
              v-for="star in 5"
              :key="star"
              class="ratings-list-item"
              :class="{ 'ml-25': star - 1 }"
            >
              <feather-icon
                icon="StarIcon"
                size="16"
                :class="[{ 'fill-current': star <= serviceHistoryProps.tripHistoryObject.user.rating }, star <= serviceHistoryProps.tripHistoryObject.user.rating ? 'text-warning' : 'text-muted']"
              />
            </li>
          </ul>
        </div>
      </b-media>
      <hr>
      <p>{{ $t('Services Overview') }}</p>
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>{{ $t('Booking Id') }}</td>
            <td>{{ serviceHistoryProps.tripHistoryObject.booking_id }}</td>
          </tr>
          <!-- <tr>
            <td>{{ $t('Total Minutes') }}</td>
            <td>{{ serviceHistoryProps.tripHistoryObject.distance }}</td>
          </tr> -->
          <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
            <td>{{ $t('Sub Total') }}</td>
            <td>{{ serviceHistoryProps.tripHistoryObject.payment.fixed }} {{ currencyInfo }}</td>
          </tr>
          <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
            <td>{{ $t('Tax') }}</td>
            <td>{{ serviceHistoryProps.tripHistoryObject.payment.tax }} {{ currencyInfo }}</td>
          </tr>
          <!-- <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
            <td>{{ $t('Extra Charges') }}</td>
            <td>{{ serviceHistoryProps.tripHistoryObject.payment.extra_charges }} {{ currencyInfo }}</td>
          </tr> 
          <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
            <td>{{ $t('Tips') }}</td>
            <td>{{ serviceHistoryProps.tripHistoryObject.payment.tips }} {{ currencyInfo }}</td>
          </tr>
            <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
            <td>{{ $t('Wallet Detection') }}</td>
            <td>{{ serviceHistoryProps.tripHistoryObject.payment.wallet }} {{ currencyInfo }}</td>
          </tr> -->
          <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
            <td>{{ $t('Total') }}</td>
            <td>{{ serviceHistoryProps.tripHistoryObject.payment.total }} {{ currencyInfo }}</td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import { BMedia, BAvatar, BRow, BCol, BCard } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BMedia,
    BAvatar,
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
    BCard,
  },
  props: {
    serviceHistoryProps: {
      type: Object,
      default: () => {},
    },
    showReSechdule: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currencyInfo() {
      if (!this.serviceHistoryProps) return null
      if (!this.serviceHistoryProps.tripHistoryObject) return null
      return this.serviceHistoryProps.tripHistoryObject.currency
    },
    getServiceType() {
      let serviceType = ''
      if (this.serviceHistoryProps.tripHistoryObject && this.serviceHistoryProps.tripHistoryObject.service) {
        if (this.serviceHistoryProps.tripHistoryObject.service.service_category) {
          serviceType = `${this.serviceHistoryProps.tripHistoryObject.service.service_name} => ${this.serviceHistoryProps.tripHistoryObject.service.service_category.service_category_name}`
        } else if (this.serviceHistoryProps.tripHistoryObject.service.service_name) {
          serviceType = this.serviceHistoryProps.tripHistoryObject.service.service_name
        }
      }
      if (serviceType) {
        return serviceType
      }
      if (!serviceType) {
        const payload = this.serviceHistoryProps.tripHistoryObject
        if (
          payload
          && payload.items
          && payload.items[0]
          && payload.items[0].provider_service
          && payload.items[0].provider_service.main_service
          && payload.items[0].provider_service.main_service.service_category
        ) {
          serviceType = payload.items[0].provider_service.main_service.service_category.alias_name
        }
      }
      return serviceType || 'NA'
    },
    cartDetails() {
      if (this.serviceHistoryProps.tripHistoryObject && this.serviceHistoryProps.tripHistoryObject.cart_details) {
        return JSON.parse(this.serviceHistoryProps.tripHistoryObject.cart_details)
      }
      return []
    },
  },
  methods: {
    isMainServiceAvailable(item) {
      return item.provider_service && item.provider_service.main_service
    },
  },
}
</script>

<style>
#upcomingServiceModal .modal-dialog {
  max-width: 80%;
}
</style>
